import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogPostList from "../components/Blog/BlogPostList";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  query BlogCategoryTemplateQuery($id: String!) {
    category: sanityCategory(id: { eq: $id }) {
      title
      slug {
        current
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
    postCategories: allSanityCategory(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
    logo: file(relativePath: { eq: "blog/Logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 95)
      }
    }
    background: file(
      relativePath: { eq: "repeating/CTA/Background Mountain.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const BlogCategoryTemplate = (props) => {
  const { data, errors } = props;

  let slug = "";
  if (typeof window !== "undefined") {
    slug = window.location.pathname;
  }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const post = data && data.category;

  const postNodes =
    data &&
    data.posts &&
    mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
      .filter((items) =>
        items.categories.find((item) => item.title === post.title)
      );
  const postCategoryNodes =
    data && data.postCategories && mapEdgesToNodes(data.postCategories);

  return (
    <Layout>
      <SearchEngineOptimization
        title={`${post.title} | Colorado Lawyers`}
        description={`Longmont lawyers at Jorgensen, Brownell & Pepin, P.C. blog about ${post.title} and other legal topics on their website.`}
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative pt-16 md:pt-28 pb-14 md:pb-22 text-center md:text-left">
        <div className="absolute top-0 left-0 w-full h-full">
          <GatsbyImage
            image={data.background.childImageSharp.gatsbyImageData}
            className="h-full"
          />
        </div>
        <div className="container relative">
          <h1 className="mb-0">Posts in {post.title}</h1>
        </div>
      </section>

      <section className="pt-16 md:pt-24 pb-20 md:pb-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-start-1 md:col-span-8">
              {postNodes && postNodes.length > 0 && (
                <BlogPostList nodes={postNodes} />
              )}
            </div>
            <div className="md:col-end-13 md:col-span-4">
              <div className="py-6 px-5 text-center shadow-5xl rounded-md mb-14">
                <GatsbyImage
                  image={data.logo.childImageSharp.gatsbyImageData}
                  className="mb-5"
                />
                <p className="text-sm">
                  Follow us on social media for more news and insights from our
                  knowledgeable team of lawyers.
                </p>
                <div className="flex items-center justify-center space-x-6">
                  <a
                    href="https://www.facebook.com/jorgensenbrownellandpepin/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-600 hover:text-primary-50"
                  >
                    <i className="fab fa-facebook-f text-xl"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/jorgensen-brownell-&-pepin-p-c-/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-600 hover:text-primary-50"
                  >
                    <i className="fab fa-linkedin-in text-xl"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCg7Lreac4Y1jwjHpacx6ROg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-600 hover:text-primary-50"
                  >
                    <i className="fab fa-youtube text-xl"></i>
                  </a>
                </div>
              </div>

              <div className="mb-14">
                <div className="text-secondary-50 text-xl font-normal font-display mb-6">
                  Categories
                </div>
                {postCategoryNodes && (
                  <ul className="space-y-1.5">
                    {postCategoryNodes.map(
                      (node, i) =>
                        node.title && (
                          <li
                            className="font-display font-bold text-sm"
                            key={i}
                          >
                            <AniLink
                              fade
                              to={`/blog/categories/${node.slug.current}/`}
                              className={`text-primary-600 hover:text-primary-50 no-underline flex items-center ${
                                slug ===
                                  `/blog/categories/${node.slug.current}/` &&
                                "text-primary-50"
                              }`}
                            >
                              {slug ===
                                `/blog/categories/${node.slug.current}/` && (
                                <div className="w-4 h-0.5 bg-primary-50 mr-2.5"></div>
                              )}
                              {node.title}
                            </AniLink>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </div>

              {/* <div className="mb-14">
                <p className="text-secondary-50 text-xl font-normal font-display">
                  Call to action lorem ipsum dolor sit amet conseceteur
                </p>
                <ButtonSolid modal="modal-contact" text="Contact Us" />
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default BlogCategoryTemplate;
